

.App {
   width: 100%;
   height: 100%;
   box-sizing: border-box;
}

#header {
   height: 15%;
}

#footer {
   height: 15%;
}


