

#marqueeWrap {
   position: fixed;
   top: 0px;
   left: 0px;
   width: 100%;
   height: 15%;
   white-space: nowrap;
   overflow: hidden;
   z-index: 2;
}
#marquee {
   display: inline-block;
   animation: marquee 70s linear infinite;
   padding-top: 0.5rem; /* 5px */
}
.msgs-collection-1 {
   position: relative;
   left: 0%;
   animation: swap 70s linear infinite;
}
.msg {
   padding-left: 1rem;
   padding-right: 3rem;
   display: inline-block;
   font-size: 2rem;
   color: rgb(35, 35, 35);
   letter-spacing: 0.5px;
   cursor: pointer;
}


/* small portrait screen */
@media(orientation: portrait) and (max-width: 640px) {
   .msg {
      font-size: 1.5rem;
   }
}

/* small landscape screen  */
@media(orientation: landscape) and (max-height: 640px) {
   .msg {
      font-size: 1.5rem;
   }
}


@keyframes marquee {
   0%   { transform: translateX(0); }
   100% { transform: translateX(-100%); }
}
@keyframes swap {
   0%, 50% { left: 0%; }
   50.01%,100% { left: 100%; }
 }